import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';
import { CommonMap } from 'client/tracking/maps/common';
import { generateTrackingIds } from 'site-modules/shared/utils/make-model-zip';
import { getSearchByModuleTrackingMap } from 'client/tracking/maps/common/search-by-module';
import { AppraisalTabsMap } from 'client/tracking/maps/appraisal/common/appraisal-tabs';
import { PartnerOfferMap } from 'client/tracking/maps/appraisal/common/partner-offer';
import { SuggestedVehiclePricingCarouselMap } from 'site-modules/shared/components/suggested-vehicle-pricing-carousel/tracking';

const PAGE_NAME = 'used_cars_tmv_appraiser';
export const MMZ_TRACKING_IDS = generateTrackingIds(PAGE_NAME);

const map = {
  [MMZ_TRACKING_IDS.makeSelectTrackingId]: TrackingEvent.actionStart(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.MAKE_SELECT
  ),

  [MMZ_TRACKING_IDS.modelSelectTrackingId]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.MODEL_SELECT
  ),

  [MMZ_TRACKING_IDS.yearSelectTrackingId]: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.YEAR_SELECT
  ),

  [MMZ_TRACKING_IDS.submitTrackingId]: TrackingEvent.actionEnd(
    TrackingConstant.ACTION_MMY_SELECT,
    TrackingConstant.SUBMIT_MMY
  ),

  ...getSearchByModuleTrackingMap(MMZ_TRACKING_IDS),

  select_step: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.SUBACTION_STEP_SELECT
  ),
  submit_location_style: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_LOCATION_STYLE
  ),
  submit_colors_options: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_COLORS_OPTIONS
  ),
  submit_condition_mileage: TrackingEvent.actionEnd(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_CONDITION_MILEAGE
  ),
  submit_condition: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_CONDITION
  ),
  submit_mileage: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_MILEAGE
  ),
  update_mileage: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.UPDATE_MILEAGE
  ),
  submit_zipcode: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_ZIPCODE
  ),
  submit_location: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_LOCATION
  ),
  submit_year: TrackingEvent.actionProgress(TrackingConstant.ACTION_APPRAISE_VEHICLE, TrackingConstant.SUBMIT_YEAR),
  submit_style: TrackingEvent.actionProgress(TrackingConstant.ACTION_APPRAISE_VEHICLE, TrackingConstant.SUBMIT_STYLE),
  submit_exterior_color: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_EXTERIOR_COLOR
  ),
  submit_interior_color: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_INTERIOR_COLOR
  ),
  vin_decode_exterior_color_match: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBACTION_VIN_DECODE_EXTERIOR_COLOR_MATCH
  ),
  vin_decode_interior_color_match: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBACTION_VIN_DECODE_INTERIOR_COLOR_MATCH
  ),
  submit_options: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_OPTIONS
  ),
  submit_low_value_options: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_LOW_VALUE_OPTIONS
  ),
  submit_transmission: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.USER_SELECTED_TRANSMISSION_TYPE
  ),
  submit_type_dropdown: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_TYPE_DROPDOWN
  ),
  submit_trim_dropdown: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_TRIM_DROPDOWN
  ),
  submit_style_dropdown: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_STYLE_DROPDOWN
  ),
  view_detailed_report: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.VIEW_DETAILED_REPORT
  ),
  add_estimated_payment: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.ADD_EST_PAYMENT
  ),
  close_report: TrackingEvent.actionCompleted(TrackingConstant.ACTION_RESET_NAVIGATION, TrackingConstant.CLOSE_DRAWER),
  view_appraisal_form: TrackingEvent.actionStart(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.VIEW_APPRAISAL_FORM
  ),
  appraise_vehicle: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.ACTION_APPRAISE_VEHICLE
  ),
  appraisal_below_the_fold_expand: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_DETAILS
  ),
  appraisal_below_the_fold_collapse: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.HIDE_DETAILS
  ),
  write_review: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_WRITE_REVIEW
  ),
  offer_selected: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.OFFER_SELECTED
  ),
  estimate_selected: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.ESTIMATE_SELECTED
  ),
  view_offer: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SUBACTION_VIEW_APPRAISAL
  ),
  submit_vin: TrackingEvent.actionProgress(TrackingConstant.ACTION_APPRAISE_VEHICLE, TrackingConstant.SUBMIT_VEHICLE),
  view_condition_questions: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_SHOW_CONTENT,
    TrackingConstant.VIEW_CONDITION_QUESTIONS
  ),
  view_appraisal: TrackingEvent.actionCompleted(TrackingConstant.ACTION_SHOW_CONTENT, TrackingConstant.VIEW_APPRAISAL),
  report_tab_select: TrackingEvent.actionStart(TrackingConstant.ACTION_TOOL_SELECT, TrackingConstant.REPORT_TAB),
  edit_mileage: TrackingEvent.actionCompleted(TrackingConstant.ACTION_APPRAISE_VEHICLE, TrackingConstant.EDIT_MILEAGE),
  edit_color: TrackingEvent.actionCompleted(TrackingConstant.ACTION_APPRAISE_VEHICLE, TrackingConstant.EDIT_COLOR),
  edit_options: TrackingEvent.actionCompleted(TrackingConstant.ACTION_APPRAISE_VEHICLE, TrackingConstant.EDIT_OPTIONS),
  edit_vehicle: TrackingEvent.actionCompleted(TrackingConstant.ACTION_APPRAISE_VEHICLE, TrackingConstant.EDIT_VEHICLE),
  /**
   * partner offer tracking
   */
  instant_offer_click: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.GET_INSTANT_OFFER
  ),
  partner_submit_mileage: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.MILEAGE_INPUT
  ),
  partner_submit_questions: TrackingEvent.actionEnd(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.SUBMIT_CONDITION_QUESTIONS
  ),
  partner_condition_questions_validation: TrackingEvent.actionEnd(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.CONDITION_QUESTIONS_VALIDATION
  ),
  partner_view_offer: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.SUBACTION_VIEW_PARTNER_OFFER
  ),
  correct_squish_vin: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.SUBACTION_CORRECT_SQUISHVIN
  ),
  correct_squish_vin_secondary: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.SUBACTION_CORRECT_SQUISHVIN_SECONDARY
  ),
  partner_view_appraisal_report: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.VIEW_APPRAISAL_REPORT
  ),
  partner_appraisal_range_min: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.MIN_APPRAISAL_RANGE_VALUE
  ),
  partner_appraisal_range_max: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.MAX_APPRAISAL_RANGE_VALUE
  ),
  offer_engage_survey: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_ENGAGE_SURVEY,
    TrackingConstant.COMPLETE_OFFER_SURVEY
  ),
  epo_submit_vin: TrackingEvent.actionProgress(TrackingConstant.ACTION_RECEIVE_EPO, TrackingConstant.SUBMIT_VEHICLE),
  click_sell_my_car: TrackingEvent.actionCompleted(TrackingConstant.ACTION_RECEIVE_EPO, TrackingConstant.SELL_MY_CAR),
  click_see_all_for_sale: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    TrackingConstant.VIEW_SEARCH_RESULT
  ),
  appraisal_checkout_clickout: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.SUBACTION_APPRAISAL_CHECKOUT_CLICKOUT
  ),
  instant_offer_checkout_dropoff: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.SUBACTION_APPRAISAL_CHECKOUT_DROPOFF
  ),
  instant_offer_checkout_schedule_appt: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.SUBACTION_APPRAISAL_CHECKOUT_SCHEDULE_APPT
  ),
  instant_offer_checkout_pickup: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.SUBACTION_APPRAISAL_CHECKOUT_PICKUP
  ),
  view_more_dealer_locations: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_DEALER_ENGAGEMENT,
    TrackingConstant.VIEW_MORE_LOCATIONS
  ),
  click_view_dealer_page: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_DEALER_ENGAGEMENT,
    TrackingConstant.VIEW_DEALER_DETAILS
  ),
  call_dealer: TrackingEvent.actionCompleted(TrackingConstant.ACTION_DEALER_ENGAGEMENT, TrackingConstant.CALL_DEALER),
  find_style: TrackingEvent.actionCompleted(TrackingConstant.ACTION_SHOW_CONTENT, TrackingConstant.FIND_STYLE),
  find_options: TrackingEvent.actionCompleted(TrackingConstant.ACTION_SHOW_CONTENT, TrackingConstant.FIND_OPTIONS),
  find_vin: TrackingEvent.actionCompleted(TrackingConstant.ACTION_SHOW_CONTENT, TrackingConstant.FIND_VIN, {
    action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
  }),
  unlock_appraisal: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SUBACTION_UNLOCK_APPRAISAL
  ),
  click_share_widget: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_SHOW_CONTENT,
    TrackingConstant.SUBACTION_SHARE_CHART
  ),
  offer_renewal_modal_renew_offer: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.SUBACTION_RENEW_OFFER
  ),
  offer_renewal_modal_no_thanks: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_SHOW_CONTENT,
    TrackingConstant.ACTION_CLOSE
  ),
  option_select: TrackingEvent.actionStart(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.SUBACTION_OPTION_SELECT
  ),
  track_car_value_cta_click: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SUBACTION_TRACK_CAR_VALUE
  ),
  style_select: TrackingEvent.actionProgress(TrackingConstant.ACTION_MMY_SELECT, TrackingConstant.STYLE_SELECT),
  change_vin: TrackingEvent.actionProgress(TrackingConstant.ACTION_APPRAISE_VEHICLE, TrackingConstant.CHANGE_VIN),
  /* multi offer */
  close_modal: TrackingEvent.actionProgress(TrackingConstant.ACTION_RECEIVE_EPO, TrackingConstant.CLOSE_MODAL),
  multi_offer_submit_intro: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.SUBMIT_INTRO
  ),
};

export const AppraisalMap = {
  ...CommonMap,
  ...map,
  ...SuggestedVehiclePricingCarouselMap,
  ...AppraisalTabsMap,
  ...PartnerOfferMap,
};
